import api from '../../../utils/Api'

// Action type
export const GET_ORG_BY_SUBDOMAIN = 'organization/getOrgBySubdomain'
export const GET_MY_ORGANIZATIONS = 'organization/GET_MY_ORGANIZATIONS'
export const FETCH_ORG_DATA = 'organization/fetchOrgData'
export const GET_ORG_SIGNUP_CUSTOM_FORM = 'organization/GET_SIGNUP_CUSTOM_FORM'
export const CREATE_ORG_STRIPE_CONNECT_ACCOUNT = 'organization/createOrgStripeConnectAccount'
export const EDIT_ORG_STRIPE_ACCOUNT_ONBOARDING_STATUS = 'organization/editOrgStripeAccountOnboardingStatus'
export const UPDATE_ORG_STRIPE_ACCOUNT_STATUS = 'organization/updateOrgStripeAccountStatus'
export const CLEAR_ORG_PUBLIC_DATA = "organization/clearPublicData"
export const CLEAR_ORG_SIGNUP_CUSTOM_FORM = "organization/CLEAR_SIGNUP_CUSTOM_FORM";
export const CLEAR_MY_ORGANIZATIONS = "organization/CLEAR_MY_ORGANIZATIONS"
export const SET_FETCH_ORG_LOADING = "organization/SET_FETCH_ORG_LOADING";

// Action creator
export const getOrgBySubdomain = (organization) => {
    return {
        type: GET_ORG_BY_SUBDOMAIN,
        organization
    };
};

export const getMyOrganizations = (organizations) => {
    return {
        type: GET_MY_ORGANIZATIONS,
        organizations
    }
}

export const fetchOrgData = (data) => {
    return {
        type: FETCH_ORG_DATA,
        data
    };
}

export const getSignupCustomForm = (form) => {
    return {
        type: GET_ORG_SIGNUP_CUSTOM_FORM,
        form
    }
}

export const createOrgStripeConnectAccount = (data) => {
    return {
        type: CREATE_ORG_STRIPE_CONNECT_ACCOUNT,
        payload: data
    };
}

export const editOrgStripeAccountOnboardingStatus = (data) => {
    return {
        type: EDIT_ORG_STRIPE_ACCOUNT_ONBOARDING_STATUS,
        payload: data
    };
}

export const updateOrgStripeAccountStatus = (data) => {
    return {
        type: UPDATE_ORG_STRIPE_ACCOUNT_STATUS,
        payload: data
    };
}

export const clearOrgPublicData = () => {
    return {
        type: CLEAR_ORG_PUBLIC_DATA
    }
}

export const clearMyOrganizations = () => {
    return {
        type: CLEAR_MY_ORGANIZATIONS
    }
}

export const clearSignupCustomForm = () => {
    return {
        type: CLEAR_ORG_SIGNUP_CUSTOM_FORM
    }
}

export const setOrgLoading = (isLoadingOrgData) => ({
    type: SET_FETCH_ORG_LOADING,
    payload: isLoadingOrgData
  });

//  Thunk
export const createOrganizationThunk = (payload) => async (dispatch) => {
    try {
        const response = await api.post("/organization/", payload);
        if (response.ok) {
            localStorage.setItem('orgData', JSON.stringify(response.data.organization))
            return response
        }
    } catch (e) {
        throw e
    }
}

export const getOrgBySubdomainThunk = (subdomain) => async (dispatch) => {
    if (subdomain) {
        try {
            const headers = { 'X-Subdomain': subdomain };
            const response = await api.getNonAuthWithHeaders('/organization/public/', null, headers);
            if (response.ok) {
                dispatch(getOrgBySubdomain(response.data));
                return response;
            } else {
                const errorMessage = response.data && response.data.error ? response.data.error : "Unexpected error occurred while fetching organization data. Please try again.";
                throw new Error(errorMessage);
            }
        } catch (error) {
            throw error
        }
    }
};

export const fetchOrganizationThunk = () => async (dispatch, getState) => {
    const { isLoadingOrgData } = getState().auth;
    if (!isLoadingOrgData) {
        dispatch(setOrgLoading(true));
        try {
            const response = await api.get('/organization/');
            if (response.ok) {
                dispatch(fetchOrgData(response.data));
                localStorage.setItem('orgData', JSON.stringify(response.data.organization));
                dispatch(setOrgLoading(false));
                return response;
            } else {
                const errorMessage = response.data && response.data.error ? response.data.error : "Unexpected error occurred while fetching organization data. Please try again.";
                dispatch(setOrgLoading(false));
                throw new Error(errorMessage);
            }
        } catch (error) {
            dispatch(setOrgLoading(false));
            throw error;
        }
    }
};

export const createStripeAccountThunk = () => {
    return async (dispatch) => {
        try {
            const response = await api.post('/organization/stripe-connected-account/');
            if (response.ok) {
                dispatch(createOrgStripeConnectAccount(response.data));
            } else {
                const errorMessage = response.data && response.data.error ? response.data.error : "Unexpected error occurred while creating a Stripe connect account. Please try again.";
                throw new Error(errorMessage);
            }
        } catch (error) {
            throw error
        }
    };
};

export const editOrgStripeOnboardingStatusThunk = () => {
    return async (dispatch) => {
        try {
            const response = await api.post('/organization/stripe-onboarding-link/');
            if (response.ok) {
                dispatch(editOrgStripeAccountOnboardingStatus(response.data));
                await dispatch(fetchOrganizationThunk())
                return response.data;
            } else {
                const errorMessage = response.data && response.data.error ? response.data.error : "Unexpected error occurred in Stripe account onboarding process. Please try again.";
                throw new Error(errorMessage);
            }

        } catch (error) {
            throw error
        }
    };
}

export const updateOrgStripeAccountStatusThunk = () => {
    return async (dispatch) => {
        try {
            const response = await api.post('/organization/stripe-account-status/');
            if (response.ok) {
                dispatch(updateOrgStripeAccountStatus(response.data));
                await dispatch(fetchOrganizationThunk())
                return response.data;
            } else {
                const errorMessage = response.data && response.data.error ? response.data.error : "Unexpected error occurred while update Stripe account status. Please try again.";
                throw new Error(errorMessage);
            }

        } catch (error) {
           throw error
        }
    };
}

export const getSignupCustomFormThunk = () => async (dispatch) => {
    try {
        const response = await api.get('/organization/admin-form/')
        if (response.status === 200) {
            const form = JSON.parse(response.data.form || '[]')
            dispatch(getSignupCustomForm(form));
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export const createSignupCustomFormThunk = (payload) => async (dispatch) => {
    try {
        const response = await api.post('/organization/invite-form/', payload)
        if (response.ok) return response
    } catch (error) {
        throw error
    }
}

export const getAllMyOrgsThunk = () => async (dispatch) => {
    try {
        const response = await api.get('/organization/my-organizations/')
        if (response.ok) {
            dispatch(getMyOrganizations(response.data))
            return response
        } else {
            const errorMessage = response.data && response.data.error ? response.data.error : "Unexpected error occurred while fetching all organizations. Please try again.";
            throw new Error(errorMessage);
        }
    } catch (error) {
        throw error
    }
}
