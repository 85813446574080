import api from '../../../utils/Api';

//Action type
export const GET_ALL_FORMS = 'forms/getAllForms';
export const CREATE_FORM = 'forms/createForm';
export const EDIT_FORM = 'forms/editForm';
export const GET_FORM_RESPONSES = 'forms/getFormResponses';
export const GET_SELECTED_FORM = 'forms/getSelectedForm';
export const GET_ORG_FROMS_RESPONSES = 'forms/getOrgFormsResponses';
export const CLEAR_SELECTED_FORM = 'forms/clearSelectedForm';
export const GET_PUBLIC_FORM = 'forms/getPublicForm';
// Action creator
export const getAllForms = (forms, totalPages = 0, params = 0) => {
  return {
    type: GET_ALL_FORMS,
    forms,
    totalPages,
    params,
  };
};

export const createForm = form => {
  return {
    type: CREATE_FORM,
    form,
  };
};

export const editForm = form => {
  return {
    type: EDIT_FORM,
    form,
  };
};

export const getFormResponses = (OrgFormResponses, totalPages = 0, params = 0) => {
  return {
    type: GET_FORM_RESPONSES,
    OrgFormResponses,
    totalPages,
    params,
  };
};

export const getOrgAllFormsResponses = formResponses => {
  return {
    type: GET_ORG_FROMS_RESPONSES,
    formResponses,
  };
};

export const selectForm = form => ({
  type: GET_SELECTED_FORM,
  form,
});

export const clearSelectedForm = () => {
  return {
    type: CLEAR_SELECTED_FORM,
  };
};

export const getPublicForm = publicForm => {
  return {
    type: GET_PUBLIC_FORM,
    publicForm,
  };
};

//Thunk
export const getOrganizationFormsThunk =
  (params = {}) =>
  async dispatch => {
    try {
      const response = await api.get('/form/', params);
      if (response.ok) {
        const { forms, total } = response.data;
        dispatch(getAllForms(forms, total, params));
      } else {
        // Handle error without dispatching an action
        return { ...response.data, isSuccess: false };
      }
    } catch (error) {
      throw error;
    }
  };

export const createFormThunk = data => async (dispatch, getState) => {
  try {
    const response = await api.post('/form/', data);
    if (response.ok && response.status === 201) {
      // Dispatch success action
      dispatch(createForm(response.data));
      dispatch(getOrganizationFormsThunk({ page: 1, perPage: getState().forms.params.perPage }));
      return { ...response.data, isSuccess: true };
    } else {
      // Handle error without dispatching an action
      return { ...response.data, isSuccess: false };
    }
  } catch (error) {
    // Handle error without dispatching an action
    throw error;
  }
};

export const editFormThunk = data => async dispatch => {
  const { formId } = data;
  try {
    const response = await api.put(`/form/${formId}/change/`, data);
    if (response.ok) {
      // Dispatch success action
      dispatch(editForm(response.data));
      return { ...response.data, isSuccess: true };
    } else {
      // Handle error without dispatching an action
      return { ...response.data, isSuccess: false };
    }
  } catch (error) {
    // Handle error without dispatching an action
    throw error;
  }
};

export const getFormResponsesThunk =
  (formId, params = {}) =>
  async dispatch => {
    try {
      const response = await api.get(`/form/${formId}/form-responses/`, params);
      if (response.ok) {
        const { formResponses, total } = response.data;
        dispatch(getFormResponses(formResponses, total, params));
      } else {
        // Handle error without dispatching an action
        return { ...response.data, isSuccess: false };
      }
    } catch (error) {
      throw error;
    }
  };

export const getOrgFormsResponsesThunk = () => async dispatch => {
  try {
    const response = await api.get('/form/all-form-responses/');
    dispatch(getOrgAllFormsResponses(response.data));
  } catch (error) {
    throw error;
  }
};

export const getPublicFormThunk = slug => {
  return async dispatch => {
    try {
      const response = await api.getNonAuth(`/form/public/${slug}/`);
      if (response.ok) {
        dispatch(getPublicForm(response.data));
      }
    } catch (error) {
      throw error;
    }
  };
};

export const submitFormResponse = payload => async () => {
  try {
    const response = await api.postNonAuth(`/form/form-responses/`, payload);
    if (response.ok) return response;
  } catch (error) {
    throw error;
  }
};
