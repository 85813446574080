import api from "../../../utils/Api";
import useJwt from "../../../auth/jwt/useJwt";
import DataStore from "../../../utils/DataStore";

const config = useJwt.jwtConfig;

export const GET_ORG_MEMBERSHIP_DATA = "membership/LOAD";
export const CLEAR_ORG_MEMBERSHIP_DATA = "membership/CLEAR";
export const LOGIN = "user/LOGIN";
export const LOGIN_WITH_ORG_DATA = "user/LOGINWITHORG"
export const LOGOUT = "user/LOGOUT";
export const CLEAR_USER_DATA = "user/CLEAR";
export const USER_ACCOUNT_CHECK ="user/USER_ACCOUNT_CHECK"
export const UPDATE_USER_STATE = "user/updateUserState"
export const SET_IS_LOADING_USER_DATA = "auth/SET_IS_LOADING_USER_DATA";


export const loginAction = (data) => {
  return {
    type: LOGIN,
    payload:data
  }
}

export const updateUserStateAction = (userData) =>{
  return {
    type: UPDATE_USER_STATE,
    userData
  }
}

const logoutAction = () => {
  return {
    type: LOGOUT,
  }
}

export const clearUserData = () => {
  return {
    type: CLEAR_USER_DATA
  }
}

const getOrgMembership = (membershipData) => {
  return {
    type: GET_ORG_MEMBERSHIP_DATA,
    membershipData
  };
};

export const clearOrgMembership = () => {
  return {
    type: CLEAR_ORG_MEMBERSHIP_DATA
  }
}

export const loginWithOrgData = () => {
  return {
    type:LOGIN_WITH_ORG_DATA
  }
}

export const userAccountCheck = (data) => {
  return {
    type: USER_ACCOUNT_CHECK,
    data
  }
}

export const setLoading = (isLoadingUserData) => ({
  type: SET_IS_LOADING_USER_DATA,
  payload: isLoadingUserData
});


export const handleCreateUserThunk = (data) => async () => {
  try {
    const response = await api.postNonAuth("/auth/register/", data)
    return response;
  } catch (error) {
    throw error
  }
}

export const handleCreateGoogleUserThunk = (data) => async (dispatch) => {
  try {
    const response = await api.postNonAuth("/auth/register/google/", data)
    if (response.ok) {
      dispatch(loginAction(response.data))
      return response
    }
    else throw new Error("Creating account with Google failed. Please try again later.")
  } catch (error) {
    throw error
  }
}

export const handleUserAccountCheck = (data) => async () => {
  try {
    const response = await api.postNonAuth('/auth/user_account_check/', data);
    return response;
  } catch (error) {
    throw error
  }
};

export const handleLogin = (payload) => async (dispatch) => {
  try {
    const response = await api.postNonAuth('/auth/login/', payload);
    dispatch(loginAction(response.data))
    return response;
  } catch (error) {
    throw error
  }
};

export const handleGoogleLoginThunk = (data) => async (dispatch) => {
  try {
    const response = await api.postNonAuth("/auth/login/google/", data)
    if (response.ok) {
      dispatch(loginAction(response.data))
      return response
    }
    else throw new Error("Log in with Google failed. Please try again later.")
  } catch (error) {
    throw error
  }
}

export const loginWithOrgDataThunk = (payload) => async (dispatch) => {
  try {
    const response = await api.postNonAuth('/auth/login_with_org/', payload);
    dispatch(loginAction(response.data))
    return response;
  } catch (error) {
    throw error
  }
};


export const handleMemberLogin = (payload) => async (dispatch) => {
  try {
    const response = await api.postNonAuth('/auth/member_login/', payload);
    dispatch(loginAction(response.data))
    return response;
  } catch (error) {
    throw error
  }
};

export const handleMemberSignup = (payload) => async (dispatch) => {
  try {
    const response = await api.postNonAuth('/auth/member_signup/', payload);
    dispatch(loginAction(response.data))
    return response;
  } catch (error) {
    throw error
  }
}

export const handleInvitationAcceptLogin = (payload) => async () => {

    try {
      const response = await api.postNonAuth('/auth/accept_invitation/', payload);
      return response;
    } catch (error) {
      throw error
  }

}

export const handleLoginWithOTP = (payload) => async (dispatch) => {
  try {
    const response = await api.postNonAuth('/auth/login_with_otp/', payload);
    dispatch(loginAction(response.data))
    return response;
  } catch (error) {
    throw error
  }
}

export const getUserThunk = () => async (dispatch, getState) => {
  const { isLoadingUserData } = getState().auth;
  if(!isLoadingUserData) {
    try {
      dispatch(setLoading(true));
      const response = await api.get('/auth/user/')
      dispatch(loginAction(response.data))
      dispatch(setLoading(false))
      return response;
    } catch (error) {
      dispatch(setLoading(false))
      throw error
    }
  }

}

export const handleLogout = () => {
  return (dispatch) => {
    dispatch(logoutAction());
    dispatch(clearUserData());

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem("userData");
    DataStore.remove('access_token')
    localStorage.removeItem(config.storageTokenKeyName);
    localStorage.removeItem(config.storageRefreshTokenKeyName);
  };
};

// Get user membership details related with the current organization
export const getOrgMembershipThunk = () => async (dispatch) => {
  try {
    const response = await api.get("/member/my_org_membership/");
    dispatch(getOrgMembership(response.data.profile));
    return response
  } catch (error) {
    throw error
  }
};

export const editOrgMembershipThunk = (payload) => async (dispatch) => {
  try {
    const response = await api.put("/member/", payload);
    dispatch(getOrgMembership(response.data));
    return response
  } catch (error) {
    throw error
  }
}

export const changeUserPassword = (data) => async () => {
  const response = await api.post("/auth/change_password/", data);
  return response;
};

export const handleGoogleSignupThunk = (data) => async (dispatch) => {
  try {
    const response = await api.postNonAuth('/auth/member-signup/google/', data)
    if (response.ok) {
      dispatch(loginAction(response.data))
      return response
    } else throw new Error("Creating membership failed. Please try again later.")
  }
  catch (error) {
    throw error
  }
}

export const handleGoogleSigninThunk = (payload) => async (dispatch) => {
  try {
    const response = await api.postNonAuth('/auth/member-signin/google/', payload)
    if (response.ok) {
      dispatch(loginAction(response.data))
      return response
    } else throw new Error("Creating membership failed. Please try again later.")
  }
  catch (error) {
    throw error
  }
}
