// ** Initial State
import { GET_RSVP, GET_EVENTS, SELECT_EVENT_LIST, GET_PUBLIC_EVENT_BY_SLUG } from '../../actions/eventAction'

const initialState = {
  publicEvent :{},
  allData: [],
  projectList: [],
  data: [],
  publicEvents: [],
  total: 1,
  params: {},
  selectedUser: null,
  selectedEventList: []
}

const events = (state = initialState, action) => {
  switch (action.type) {
    case GET_RSVP:
      const userList = action.payload.data.map(m => ({
        ...m,
        form_response: m.rsvp_form_response.length ? JSON.parse(m.rsvp_form_response[0].response) : {}
      }));
      return { ...state, data: userList, total: action.payload.totalPages, params: action.payload.params };

    case GET_EVENTS:
      return { ...state, publicEvents: action.payload.data };
    case GET_PUBLIC_EVENT_BY_SLUG:
      return {...state, publicEvent : action.payload };
    case SELECT_EVENT_LIST:
      return { ...state, selectedEventList: action.payload };

    default:
      return state;
  }
}
export default events
