// ** Initial State
const initialState = {
  allData: [],
  projectList: [],
  data: [],
  total: 1,
  params: {},
  publicEvents: [],
  selectedSubscriberList: null,
  subscriberLists: [],
  selectedUser: null
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SUBSCRIBERS':
      
      let data = action.data
      return {
        ...state,
        data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'SELECT_LIST':
      
      return { ...state, selectedSubscriberList: action.data }
    case 'GET_LIST1':
      let subscriberLists = []
      action.data.forEach(entry => {
        subscriberLists.push({ ...entry, value: entry.pk, label: entry.name })
      })
      
      return {
        ...state,
        subscriberLists
      }
    case 'GET_PUBLIC_EVENTS':
      let eventList = [{ label: 'All Events', value: undefined }]
      action.data.forEach(e => {
        eventList.push({ label: `${e.title} - ${e.start.split("T")[0]}`, value: e.id })
      })
      
      return { ...state, publicEvents: eventList }
    case 'GET_PROJECT':
      let projectList = []
      let defaultProject = { label: 'All Projects', name: 'All Projects', value: undefined }
      projectList.push(defaultProject)
      action.data.forEach(proj => {
        projectList.push({ label: proj.name, value: proj.pk })
      });

      return { ...state, projectList }
    case 'ADD_SUBSCRIBER':
      return { ...state }
    case 'DELETE_SUBSCRIBER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
