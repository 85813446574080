import { setupTokenAndLoad } from '../../../utils/Auth';
import {
    GET_ORG_BY_SUBDOMAIN,
    FETCH_ORG_DATA,
    CREATE_ORG_STRIPE_CONNECT_ACCOUNT,
    EDIT_ORG_STRIPE_ACCOUNT_ONBOARDING_STATUS,
    UPDATE_ORG_STRIPE_ACCOUNT_STATUS,
    CLEAR_ORG_PUBLIC_DATA,
    CLEAR_ORG_SIGNUP_CUSTOM_FORM,
    GET_ORG_SIGNUP_CUSTOM_FORM,
    GET_MY_ORGANIZATIONS,
    CLEAR_MY_ORGANIZATIONS,
    SET_FETCH_ORG_LOADING,

} from '../../actions/organizationAction';

const initialState = {
    orgPublicData: {},
    isLoadingOrgData: false,
    organization: {},
    myOrganizations: [],
    stripeConnectedAccountData: {},
    stripeOnboardingStatus: {},
    currentStripeStatus: {},
    signupCustomForm: [],
};

const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FETCH_ORG_LOADING:
            return {
              ...state,
              isLoadingOrgData: action.payload,
            };
        case GET_ORG_BY_SUBDOMAIN:
            return {
                ...state,
                orgPublicData: action.organization
            };
        case GET_MY_ORGANIZATIONS:
            return {
                ...state,
                myOrganizations: action.organizations
            }
        case FETCH_ORG_DATA:
            if (action.data.token && action.data.user_data) setupTokenAndLoad(action.data.token, action.data.user_data)
            return {
                ...state,
                organization: action.data.organization
            };

        case GET_ORG_SIGNUP_CUSTOM_FORM:
            return {
                ...state,
                signupCustomForm: action.form
            }

        case CREATE_ORG_STRIPE_CONNECT_ACCOUNT:
            return {
                ...state,
                stripeConnectedAccountData: action.payload
            };

        case EDIT_ORG_STRIPE_ACCOUNT_ONBOARDING_STATUS:
            return {
                ...state,
                stripeOnboardingStatus: action.payload
            };

        case UPDATE_ORG_STRIPE_ACCOUNT_STATUS:
            return {
                ...state,
                currentStripeStatus: action.payload
            };
        case CLEAR_ORG_PUBLIC_DATA:
            return {
                ...state,
                orgPublicData: {}
            }
        case CLEAR_ORG_SIGNUP_CUSTOM_FORM:
            return {
                ...state,
                signupCustomForm: []
            }
        case CLEAR_MY_ORGANIZATIONS:
            return {
                ...state,
                myOrganizations: [],
            }
        default:
            return state;
    }
};

export default organizationReducer;
