export default {
  // landingDomain: 'http://app.localhost:3000/',
  // baseDomain: 'http://localhost:3001/',
  // appDomain: 'http://app.localhost:3001/',
  // apiEndPoint: 'http://localhost:8085/',

  //Test Mode
  // stripePublicKey: 'pk_test_51Lup0jG1vgiymCwVxGCNoxbzWqd0bvMTg5KUCHlK6mZEVAWZS5lafPAGlHEdmqaMlrEmYYi1ow4JkNKnYaN7raj300g4Kx5PB0',

  // Live Mode
  // stripePublicKey: "pk_live_51Lup0jG1vgiymCwVRWPYuE4SvMjAZQHxX8kcJBy4lB2heTXRtsDIlzcl4zwboavfAY69gVVQIHogKXBjiDNxuBWd000fHddoBE",

  // landingDomain: "https://goodworkhub.com/",
  // baseDomain: "https://goodworkhub.com/",
  // appDomain: "https://app.goodworkhub.com/",
  // apiEndPoint: "https://api.goodworkhub.com/",

  landingDomain: 'https://stage-gwh.xyz/',
  baseDomain: 'https://stage-gwh.xyz/',
  appDomain: 'https://app.stage-gwh.xyz/',
  apiEndPoint: 'https://api.stage-gwh.xyz/',

  // baseDomain: "https://stage-gwh.xyz/",
  // apiEndPoint: "https://api.stage-gwh.xyz/",
  firebaseConfig: {},
};
