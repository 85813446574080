import {
  CLEAR_CHECKLISTS,
  CLEAR_TEAM_MEMBERS,
  CLEAR_TEAMS,
  CLEAR_TASKS,
  CREATE_CHECKLIST,
  CREATE_TASK,
  DELETE_CHECKLIST,
  DELETE_TASK,
  EDIT_CHECKLIST,
  EDIT_TASK,
  GET_CHECKLISTS,
  GET_TEAM_MEMBERS,
  GET_TEAMS,
  GET_TASKS
} from "../../actions/planningAction/index";

const initialState = {
  checklists: [],
  tasks: [],
  teamMembers: [],
  teams: []
};

const PlanningReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECKLISTS:
      return {
        ...state,
        checklists: action.checklists
      };
    case CREATE_CHECKLIST:
      return {
        ...state,
        checklists: [...state.checklists, action.checklist]
      };
    case EDIT_CHECKLIST:
      return {
        ...state,
        checklists: state.checklists.map((checklist) => (checklist.pk === action.checklist.pk ? action.checklist : checklist))
      };
    case DELETE_CHECKLIST:
      return {
        ...state,
        checklists: state.checklists.filter((checklist) => checklist.pk !== action.checklistPk)
      };
    case CLEAR_CHECKLISTS:
      return {
        ...state,
        checklists: []
      }
    case GET_TASKS:
      return {
        ...state,
        tasks: action.tasks
      }
    case CREATE_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.task]
      }
    case EDIT_TASK:
      return {
        ...state,
        tasks: state.tasks.map((task) => (task.pk === action.task.pk ? action.task : task))
      };
    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.pk !== action.taskPk)
      };
    case CLEAR_TASKS:
      return {
        ...state,
        tasks: []
      }
    case GET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.members
      }
    case CLEAR_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: []
      }
    case GET_TEAMS:
      return {
        ...state,
        teams: action.teams
      }
    case CLEAR_TEAMS:
      return {
        ...state,
        teams: []
      }
    default:
      return state;
  }
};

export default PlanningReducer;
