import api from "../../../utils/Api";

export const GET_GRANTS = "grants/GET";
export const TOGGLE_SAVED_GRANT = "savedGrant/TOGGLE"
export const REMOVE_SAVED_GRANT = "savedGrant/REMOVE"
export const GET_SERVICE_FOCUS = "grantServiceFocus/GET"
export const GET_TOTAL_GRANTS = "totalGrants/GET_TOTAL";
export const CLEAR_GRANTS = "grants/CLEAR";
export const CLEAR_SERVICE_FOCUS = "grantServiceFocus/CLEAR";
export const CLEAR_TOTAL_GRANTS = "totalGrants/CLEAR"

const getGrantsAction = (grantsData) => {
  return {
    type: GET_GRANTS,
    grantsData,
  };
};

const toggleSavedGrantAction = (grant) => {
  return {
    type: TOGGLE_SAVED_GRANT,
    grant
  }
}

const removeSavedGrantAction = (grant) => {
  return {
    type: REMOVE_SAVED_GRANT,
    grant,
  }
}

const getServiceFocusOptionsAction = (serviceFocusOptions) => {
  return {
    type: GET_SERVICE_FOCUS,
    serviceFocusOptions,
  };
};

const getTotalAmountGrantsAction = (total) => {
  return {
    type: GET_TOTAL_GRANTS,
    total
  }
}

export const clearGrantsAction = () => {
  return {
    type: CLEAR_GRANTS,
  };
};

export const clearServiceFocusOptionsAction = () => {
  return {
    type: CLEAR_SERVICE_FOCUS,
  };
};

export const clearTotalAmountGrantsAction = () => {
  return {
    type: CLEAR_TOTAL_GRANTS
  }
}

export const getGrantsThunk = (params) => async (dispatch) => {
  try {
    const response = await api.get(`grants/all/`, params);
    dispatch(getGrantsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSavedGrantsThunk = (params) => async (dispatch) => {
  try {
    const response = await api.get(`grants/saved/`, params);
    dispatch(getGrantsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveGrantThunk = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`grants/saved/`, payload);
    dispatch(toggleSavedGrantAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const unsaveGrantThunk = (params, path) => async (dispatch) => {
  try {
    const response = await api.delete(`grants/saved/`, params);
    if (path.includes('matched')) dispatch(toggleSavedGrantAction(response.data));
    else if (path.includes('saved-grants')) dispatch(removeSavedGrantAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getServiceFocusOptionsThunk = () => async (dispatch) => {
  try {
    const response = await api.get(`grants/service-focus/`);
    dispatch(getServiceFocusOptionsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTotalAmountGrantsThunk = () => async (dispatch) => {
  try {
    const response = await api.get('grants/total');
    if (response.ok) dispatch(getTotalAmountGrantsAction(response.data.total_amount_grants));
  } catch (error) {
    throw error;
  }
}
