import { setupTokenAndLoad } from "../../../utils/Auth"
import {
         CLEAR_ORG_MEMBERSHIP_DATA,
         GET_ORG_MEMBERSHIP_DATA,
         LOGIN,
         LOGOUT,
         CLEAR_USER_DATA,
         LOGIN_WITH_ORG_DATA,
         UPDATE_USER_STATE,
         SET_IS_LOADING_USER_DATA
        } from "../../actions/auth"

const initialState = {
  userData: {},
  isLoadingUserData: false,
  membershipData: {},
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING_USER_DATA:
      return {
        ...state,
        isLoadingUserData: action.payload,
      };
    case LOGIN:
      // Set up token and user permissions if it exists
      if (action.payload?.token) setupTokenAndLoad(action.payload.token, action.payload.user_data)
      return {
        ...state,
        userData: action.payload.user_data,
      }
    case UPDATE_USER_STATE:
      return {
        ...state,
        userData: action.userData,
      }
    case LOGIN_WITH_ORG_DATA:
      return {
        ...state,
        userData: action.payload.userData
      }
    case LOGOUT:
      return { ...state, userData: {}, membershipData: {} }
    case GET_ORG_MEMBERSHIP_DATA:
      return { ...state, membershipData: action.membershipData }
    case CLEAR_ORG_MEMBERSHIP_DATA:
      return { ...state, membershipData: {} }
    case CLEAR_USER_DATA:
      return { ...state, userData: {} }
    default:
      return state
  }
}

export default authReducer
