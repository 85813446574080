import api from "../../../utils/Api";

// Action type
export const GET_ORG_PROFILE = "organizationProfile/LOAD";
export const CREATE_ORG_PROFILE = "organizationProfile/CREATE";
export const EDIT_ORG_PROFILE = "organizationProfile/EDIT"
export const ADD_WEBSITE_URL = "organizationProfile/ADD_WEBSITE_URL"
export const CLEAR_ORG_PROFILE = "organizationProfile/CLEAR";

// Action creator
export const getOrgProfile = (organizationProfile) => {
  return {
    type: GET_ORG_PROFILE,
    organizationProfile
  };
};

export const createOrgProfile = (organizationProfile) => {
  return {
    type: CREATE_ORG_PROFILE,
    organizationProfile
  };
};

export const editOrgProfile = (organizationProfile) => {
  return {
    type: EDIT_ORG_PROFILE,
    organizationProfile
  };
};

export const addWebsiteUrl = (websiteURL) => {
  return {
    type: ADD_WEBSITE_URL,
    websiteURL
  };
};

export const clearOrgProfile = () => {
  return {
    type: CLEAR_ORG_PROFILE
  };
};

//  Get organization profile thunk
export const getOrgProfileThunk = () => async (dispatch) => {
  try {
    const response = await api.get("/organization/profile/")
    if (response.ok) {
      dispatch(getOrgProfile(response.data || {}))
      return response
    } else {
      return response.data.error
    }
  } catch (error) {
    return error
  }
};

export const createOrgProfileThunk = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/organization/profile/", payload)
    if (response.ok) {
      dispatch(createOrgProfile(response.data))
      return response
    } else {
      return response
    }
  } catch (e) {
    throw e
  }
};

export const editOrgProfileThunk = (payload) => async (dispatch) => {
  try {
    const response = await api.put("/organization/profile/", payload)
    if (response.ok) {
      dispatch(editOrgProfile(response.data))
      return response
    } else {
      return response.data.error
    }
  } catch (e) {
    throw e
  }
};

export const createWebsiteURLThunk = () => async (dispatch) => {
  const response = await api.post("/organization/generate-website/")
  if (response.ok) {
    const websiteURL = response.data.website_url
    dispatch(addWebsiteUrl(websiteURL))
  }
}
