// ** Initial State
const initialState = {
  allData: [],
  projectList: [],
  subscriberLists: [],
  data: [],
  lastImportedDataCount: 0,
  total: 1,
  params: {},
  selectedUser: null
}

const subscribersImport = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SUBSCRIBERS':
      
      let data = action.data
      return {
        ...state,
        data,
        total: action.totalPages,
        params: action.params
      }
    case 'IMPORT_SUBSCRIBERS':
      
      return {
        ...state,
        importedDataCount: state.importedDataCount + action.data.length,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_LIST':
      let subscriberLists = []
      action.data.forEach(entry => {
        subscriberLists.push({ ...entry, value: entry.pk, label: entry.name })
      })
      return {
        ...state,
        subscriberLists
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'GET_PROJECT':
      let projectList = []
      action.data.forEach(proj => {
        projectList.push({ label: proj.name, value: proj.pk })
      });
      return { ...state, projectList }
    case 'ADD_SUBSCRIBER':
      return { ...state }
    case 'DELETE_SUBSCRIBER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default subscribersImport
