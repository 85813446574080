
import api from "../../../utils/Api";

// ** Get data on page or row change
export const GET_RSVP = 'events/GET_RSVP';
export const GET_EVENTS = 'events/GET_EVENTS';
export const SELECT_EVENT_LIST ='events/SELECT_EVENT_LIST'
export const GET_PUBLIC_EVENT_BY_SLUG='events/getPublicEventBySlug'

export const getRsvpAction = (data, totalPages, params) => {
    return {
        type: GET_RSVP,
        payload:{ data, totalPages, params }
    }
}

// Action Creator for getting events
export const getEventsAction= (data, params) => {
    return {
        type: GET_EVENTS,
        payload: {data, params}
    }
}

export const selectEventList = (listData) => {
    return {
        type: SELECT_EVENT_LIST,
        payload:listData
    }
}
export const getPublicEventAction = (eventData) => {
    return {
        type: GET_PUBLIC_EVENT_BY_SLUG,
        payload:eventData
    }
}
// Thunk for getting RSVP data
export const getData = params => {
    return async dispatch => {
        try {
            const response = await api.get('/event/rsvp/', params);
            if (response.ok) {
                dispatch(getRsvpAction(response.data, 10, params));
            } else {
                const errorMessage = response.data?.error ? response.data.error : "Unexpected error occurred while fetching rsvp event data. Please try again.";
                throw new Error(errorMessage);
            }
        } catch (err) {
           return err
        }
    }
}

// Thunk for getting events
export const getEvents = params => {
    return async dispatch => {
        try {
            const response = await api.get('/event/public/', params);
            if (response.ok) {
                dispatch(getEventsAction(response.data, params));
            } else {
                const errorMessage = response.data?.error ? response.data.error : "Unexpected error occurred while fetching public events. Please try again.";
                throw new Error(errorMessage);
            }
        } catch (err) {
           return err

        }
    }
}

export const getPublicEventBySlugThunk = (slug) => {
    return async dispatch => {
        try {
            const response = await api.getNonAuth(`/event/public/${slug}/`);
            if (response.ok) {
                dispatch(getPublicEventAction(response.data));
                return response.data
            } else {
                const errorMessage = response.data?.error ? response.data.error : "Unexpected error occurred while fetching public event data. Please try again.";
                throw new Error(errorMessage);
            }
        } catch (err) {
           throw err

        }
    }
}
