
import React, { createContext, useState, useContext, useRef, useEffect } from 'react';

export const FormSlideInContext = createContext();

export const useFormSlideIn = () => useContext(FormSlideInContext);

export const FormSlideInProvider = ({ children }) => {
  const [formSlideInOpen, setFormSlideInOpen] = useState(false);
  const [activeSlideIn, setActiveSlideIn] = useState('');
  const slideInRef = useRef();

  const toggleFormSlideIn = (slideInName='') => {
    // Set the name of the active slide-in
    setActiveSlideIn(slideInName);
    // Toggle the slide-in open/close state
    setFormSlideInOpen(slideInName !== activeSlideIn || !formSlideInOpen);
  };

  // Close the slide-in and reset the activeSlideIn state
  const closeFormSlideIn = () => {
    setActiveSlideIn('');
    setFormSlideInOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!slideInRef.current?.contains(event.target)) {
        closeFormSlideIn();
      }
    };

    if (formSlideInOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formSlideInOpen]);


  return (
    <FormSlideInContext.Provider value={{ formSlideInOpen, activeSlideIn, toggleFormSlideIn, closeFormSlideIn, slideInRef }}>
      {children}
    </FormSlideInContext.Provider>
  );
};
