import React, { createContext, useState, useCallback } from 'react';

const ModalContext = createContext({
  modalOpen: false,
  activeModalContent: null,
  toggleModal: () => {},
  setActiveModalContent: () => {},
});

export const ModalProvider = ({ children }) => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [activeModalContent, setActiveModalContent] = useState(null);

  const toggleModal = useCallback(() => {
    setModalOpen(prev => !prev);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        activeModalContent,
        toggleModal,
        setActiveModalContent,
        modalTitle,
        setModalTitle,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
