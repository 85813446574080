import {
  GET_ALL_FORMS,
  GET_SELECTED_FORM,
  CREATE_FORM,
  EDIT_FORM,
  GET_FORM_RESPONSES,
  GET_ORG_FROMS_RESPONSES,
  CLEAR_SELECTED_FORM,
  GET_PUBLIC_FORM,
} from '../../actions/formAction';

const initialState = {
  forms: [],
  selectedForm: {},
  formResponses: [],
  orgFromsResponses: [],
  publicForm: {},
  total: 0,
  params: {
    page: 1,
    perPage: 10,
  },
  OrgFormResponses: [],
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FORMS:
      return {
        ...state,
        forms: action.forms,
        total: action.totalPages,
        params: {
          ...state.params,
          page: action.params.page,
          perPage: action.params.perPage,
        },
      };
    case GET_SELECTED_FORM:
      return { ...state, selectedForm: action.form };
    case CREATE_FORM:
      return { ...state, forms: [...state.forms, action.form] };
    case EDIT_FORM:
      const updatedForms = state.forms.map(form => (form.pk === action.form.pk ? action.form : form));
      return { ...state, forms: updatedForms };
    case GET_FORM_RESPONSES:
      return {
        ...state,
        OrgFormResponses: action.OrgFormResponses,
        total: action.totalPages,
        params: {
          ...state.params,
          page: action.params.page,
          perPage: action.params.perPage,
        },
      };

    case GET_ORG_FROMS_RESPONSES:
      return { ...state, orgFromsResponses: action.formResponses };
    case CLEAR_SELECTED_FORM:
      return { ...state, selectedForm: {} };
    case GET_PUBLIC_FORM:
      return { ...state, publicForm: action.publicForm };
    default:
      return state;
  }
};

export default formReducer;
