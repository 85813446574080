import { FETCH_DASHBOARD_ORG_DATA } from '../actions'

// ** Initial State
const initialState = {
    events: [],
    numbers: {},
    banners: [],
    organization: {}
}

const contacts = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_EVENT':
            // ** If currentMail is not null / undefined then find and set currentMail
            return {
                ...state,
                events: action.data
            }
        case 'GET_DASHBOARD_NUMBERS':
            // ** If currentMail is not null / undefined then find and set currentMail
            return {
                ...state,
                numbers: action.data
            }
        case 'GET_DASHBOARD_BANNERS':
            // ** If currentMail is not null / undefined then find and set currentMail
            return {
                ...state,
                banners: action.data
            }
        case FETCH_DASHBOARD_ORG_DATA:
            return {
                ...state,
                organization: action.organization
            }
        default:
            return state
    }
}

export default contacts
