
import api from "../../../../utils/Api";

//Action type
export const FETCH_DASHBOARD_ORG_DATA = 'dashboard/fetchDashboardOrgData'


// Action creator
export const fetchDashboardOrgData = (organization) => {
    return {
        type: FETCH_DASHBOARD_ORG_DATA,
        organization
    };
}

//Thunk
export const fetchDashboardOrgDataThunk = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/organization/');
            if (response.ok) {
                dispatch(fetchDashboardOrgData(response.data.organization));
                localStorage.setItem('orgData', JSON.stringify(response.data.organization))
                return response
            } else {
                const errorMessage = response.data && response.data.error ? response.data.error : "Unexpected error occurred while fetching organization data. Please try again.";
                throw new Error(errorMessage);
            }

        } catch (error) {
            console.error("Unexpected error occurred:", error);
            return error
        }
    };
};


export const fetchEvents = (filter, isDefault = false) => {
    return async dispatch => {
        return api.get(`/dashboard/event/`, {}).then(response => {
            if (response.ok) {
                dispatch({
                    // type: isDefault ? 'GET_CONTACTS_DEFAULT' : 'GET_CONTACTS_COMMON',
                    type: 'GET_DASHBOARD_EVENT',
                    data: response.data
                })
            }
        });
    }
}

export const fetchNumbers = (filter, isDefault = false) => {
    return async dispatch => {
        return api.get(`/dashboard/stats/`, { filter, default: isDefault ? isDefault : undefined, include_subscribers: true }).then(response => {
            if (response.ok) {
                dispatch({
                    // type: isDefault ? 'GET_CONTACTS_DEFAULT' : 'GET_CONTACTS_COMMON',
                    type: 'GET_DASHBOARD_NUMBERS',
                    data: response.data
                })
            }
        });
    }
}

export const fetchBanners = (filter, isDefault = false) => {
    return async dispatch => {
        return api.get(`/dashboard/banner/`, { filter, default: isDefault ? isDefault : undefined, include_subscribers: true }).then(response => {
            if (response.ok) {
                dispatch({
                    // type: isDefault ? 'GET_CONTACTS_DEFAULT' : 'GET_CONTACTS_COMMON',
                    type: 'GET_DASHBOARD_BANNERS',
                    data: response.data
                })
            }
        });
    }
}
