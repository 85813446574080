import api from '../../../utils/Api';

export const GET_ALL_TEAMS = 'teams/GET_ALL';
export const GET_TEAMS = 'teams/GET';
export const GET_MEMBERS_BY_TEAM = 'teams/GET_MEMBERS_BY_TEAM';
export const CLEAR_ALL_TEAMS = 'teams/CLEAR_ALL';
export const CLEAR_TEAMS = 'teams/CLEAR';
export const CLEAR_MEMBERS_BY_TEAM = 'teams/CLEAR_MEMBERS_BY_TEAM';
export const GET_SELECTED_TEAM = 'teams/getSelectedTeam';

export const getAllTeamsAction = teamsData => {
  return {
    type: GET_ALL_TEAMS,
    teamsData,
  };
};

export const getTeamsAction = teamsData => {
  return {
    type: GET_TEAMS,
    teamsData,
  };
};

export const getMembersByTeamAction = teamMembersData => {
  return {
    type: GET_MEMBERS_BY_TEAM,
    teamMembersData,
  };
};

export const clearTeamsAction = () => {
  return {
    type: CLEAR_TEAMS,
  };
};

export const clearMembersByTeamAction = teamPk => {
  return {
    type: CLEAR_MEMBERS_BY_TEAM,
    teamPk,
  };
};

export const selectedTeamAction = team => ({
  type: GET_SELECTED_TEAM,
  team,
});

export const getAllTeamsThunk = () => async dispatch => {
  try {
    const response = await api.get('organization/teams/');
    dispatch(getAllTeamsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTeamsThunk = params => async dispatch => {
  try {
    const response = await api.get('organization/teams/', params);
    dispatch(getTeamsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getMembersByTeamThunk = params => async dispatch => {
  try {
    const response = await api.get('/member/', params);
    dispatch(getMembersByTeamAction({ teamPk: params.project, members: response.data.members }));
  } catch (error) {
    throw error;
  }
};

export const createEditTeamsThunk = (teamsData, currentPage, rowsPerPage) => async dispatch => {
  try {
    const response = await api.post('organization/teams/', teamsData);
    dispatch(getAllTeamsThunk());
    dispatch(getTeamsThunk({ page: currentPage, per_page: rowsPerPage }));
    return response;
  } catch (error) {
    console.log(error, 'backend');
    throw error;
  }
};
