import { GET_ORG_PROFILE, CREATE_ORG_PROFILE, ADD_WEBSITE_URL, CLEAR_ORG_PROFILE, EDIT_ORG_PROFILE } from "../../actions/organizationProfileAction"

const initialState = {};

const organizationProfileReducer = (state = initialState, action) => {
  const newState = { ...state }

  switch (action.type) {
    case GET_ORG_PROFILE:
    case CREATE_ORG_PROFILE:
    case EDIT_ORG_PROFILE:
      return action.organizationProfile
    case ADD_WEBSITE_URL:
      newState["website_url"] = action.websiteURL
      return newState
    case CLEAR_ORG_PROFILE:
        return {}
    default:
      return state;
  }
};

export default organizationProfileReducer;
